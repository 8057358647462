import React from 'react';

import { Page, Section } from 'src/components';

export default function Thanks() {
  return (
    <Page dark title="Enrollment Email" prefooter={<></>} color="#23282A">
      <Section
        dark
        narrow
        title="Thanks for your interest in partnering with Catch"
        subtitle="We'll follow up shortly"
      ></Section>
    </Page>
  );
}
